import React from 'react';
import { postLog } from './api';

export class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    postLog({
      title: 'Falha no app',
      error: JSON.stringify(error),
      errorInfo: JSON.stringify(errorInfo)
    });
  }

  render() {
    return this.props.children;
  }
}
