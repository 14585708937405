import 'babel-polyfill';
import './index.css';

import App from './app';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import { getQueryParams } from './utils/calculosGerais';
import { ErrorBoundary } from './ErrorBoundary';

const active = localStorage.getItem('active');
const timestamp = localStorage.getItem('timestamp');
const activeValue = active ? Math.max(parseInt(active), 0) + 1 : 1;
const value =
  timestamp && new Date().getTime() - timestamp < 6000 ? activeValue : 1;

localStorage.setItem('timestamp', new Date().getTime());
setInterval(
  () => localStorage.setItem('timestamp', new Date().getTime()),
  5000
);

window.addEventListener('load', () => {
  localStorage.setItem('active', value);
});

window.addEventListener('unload', () => {
  const active = localStorage.getItem('active');
  localStorage.setItem('active', parseInt(active) - 1);
});

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value(callback, type, quality) {
      const binStr = atob(this.toDataURL(type, quality).split(',')[1]);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      callback(new Blob([arr], { type: type || 'image/png' }));
    }
  });
}

const params = getQueryParams();

if (params.productId === params.itemId) {
  params['productId'] = '6';
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App
        itemID={params.itemId}
        productID={params.productId}
        print={params.print}
        page={params.page}
        haveCover={params.cover}
        thumb={params.thumb}
        inactive={value > 1}
        productType="photobook"
        pages={params.pages}
        back={params.back}
      />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
